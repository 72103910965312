.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

h1, p {
  margin: 0 auto;
}

.logo {
  width: 90%;
  max-width: 400px;
  height: auto;
}